import { GET_BIZ_LOG_DETAIL } from './actionType'
const defaultStore = {
    bizLogDetail: {}
}

export default (state = defaultStore, action) => {
    switch (action.type) {
        case GET_BIZ_LOG_DETAIL:
            return Object.assign(
                {},
                {
                    bizLogDetail: { ...action.data }
                }
            )
        default:
            return state
    }
}

