import { GET_SYS_EQUIPMENT, GET_SYS_OPERATION_REPORT_LOG, GET_SYS__MODEL_LIST } from './actionType';
const defaultStore = {
    menuToggle: false,
    sysEquipment: [],
    sysOperationReportLog: {},
    sysModelList: {}
}

export default (state = defaultStore, action) => {
    switch (action.type) {
        case GET_SYS_EQUIPMENT:
            return Object.assign({},{
                sysEquipment: {...action.data}
            })
        case GET_SYS_OPERATION_REPORT_LOG:
            return Object.assign({},{
                sysOperationReportLog: {...action.data}
            })
        case GET_SYS__MODEL_LIST:
            return Object.assign({},{
                sysModelList: {...action.data}
            })
        default:
            return state
    }
}