import { GET_LIST_DIARY, GET_WATER_BALANCE_LIST } from './actionType'
const defaultStore = {
  listDiary: {},
  waterBalanceList: {}
}

export default (state = defaultStore, action) => {
  switch (action.type) {
    case GET_LIST_DIARY:
      return Object.assign(
        {},
        {
          listDiary: { ...action.data }
        }
      )
    case GET_WATER_BALANCE_LIST:
      return Object.assign(
        {},
        {
          waterBalanceList: { ...action.data }
        }
      )
    default:
      return state
  }
}
