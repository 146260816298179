export const GET_SYSALARM_LIST = 'getSysAlarmList'
export const GET_INSPECTION = 'getInspection'
export const SET_INSPECTION = 'setInspection'
export const DELETE_INSPECTION = 'deleteInspection'
export const GET_SYSALARM_DETAIL = 'getSysAlarmDetail'
export const GET_SUBSCRIBE_LIST = 'getSubscribeList'
export const GET_SYSEMISSION_LIST = 'getSysEmissionList'
export const GET_INSPECTION_GROUP = 'getInspectionGroup'
export const GET_INSPECTION_PERSONNEL = 'getInspectionPersonnel'
export const GET_ALAMMODEL_LIST = 'getAlarmModelList'
export const GET_SITE_ALAMMODEL_LIST = 'getSiteAlarmModelList'
