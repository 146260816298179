import {
  GET_SYSALARM_LIST,
  GET_INSPECTION,
  GET_SYSALARM_DETAIL,
  GET_SUBSCRIBE_LIST,
  GET_SYSEMISSION_LIST,
  GET_INSPECTION_GROUP,
  GET_INSPECTION_PERSONNEL,
  GET_ALAMMODEL_LIST,
  GET_SITE_ALAMMODEL_LIST
} from './actionType'
const defaultStore = {
  sysalarmList: {},
  inspection: {},
  sysAlarmDetail: {},
  subscribeList: {},
  sysEmissionList: {},
  inspectionGroup: {},
  inspectionPersonnel: {},
  alarmModelList: {},
  siteAlarmModelList: []
}

export default (state = defaultStore, action) => {
  switch (action.type) {
    case GET_ALAMMODEL_LIST:
      return Object.assign(
        {},
        {
          alarmModelList: { ...action.data }
        }
      )
    case GET_SITE_ALAMMODEL_LIST:
      return Object.assign(
        {},
        {
          siteAlarmModelList: [...action.data]
        }
      )
    case GET_SYSALARM_LIST:
      return Object.assign(
        {},
        {
          sysalarmList: { ...action.data }
        }
      )
    case GET_INSPECTION:
      return Object.assign(
        {},
        {
          inspection: { ...action.data }
        }
      )
    case GET_SYSALARM_DETAIL:
      return Object.assign(
        {},
        {
          sysAlarmDetail: { ...action.data }
        }
      )
    case GET_SUBSCRIBE_LIST:
      return Object.assign(
        {},
        {
          subscribeList: { ...action.data }
        }
      )
    case GET_SYSEMISSION_LIST:
      return Object.assign(
        {},
        {
          sysEmissionList: { ...action.data }
        }
      )
    case GET_INSPECTION_GROUP:
      return Object.assign(
        {},
        {
          inspectionGroup: { ...action.data }
        }
      )
    case GET_INSPECTION_PERSONNEL:
      return Object.assign(
        {},
        {
          inspectionPersonnel: { ...action.data }
        }
      )
    default:
      return state
  }
}
