import {
    GET_BIZ_LOG_LIST,
    GET_POLICE_LIST,
    // GET_BIZ_LOG_DETAIL,
} from './actionType'
const defaultStore = {
    bizLogList: {},
    bizLogDetail: {},
    policeList: {},
    policeListDataArray: {}
}

export default (state = defaultStore, action) => {
    switch (action.type) {
        case GET_BIZ_LOG_LIST:
            return Object.assign(
                {},
                {
                    bizLogList: { ...action.data }
                }
            )
        case GET_POLICE_LIST:
            return Object.assign(
                {},
                {
                    policeList: { ...action.data }
                }
            )
        // case GET_BIZ_LOG_DETAIL:
        //     return Object.assign({},{
        //         bizLogDetail: {...action.data}
        //     })
        default:
            return state
    }
}

