import { combineReducers } from 'redux'
import home from '../systemPsages/Home/reducer'
import deviceManagement from '../systemPsages/DeviceManagement/reducer'
import dataManagement from '../systemPsages/DataManagement/reducer'
import alarmManagement from '../systemPsages/AlarmManagement/reducer'
import details from '../systemPsages/Details/reducer'
import customer from '../systemPsages/Customer/reducer'
import waterBakance from '../systemPsages/Home/FactoryPage/WaterBalance/reducer'
import { MENU_TOGGLE, OPENPAGELOADING, CLOSEPAGELOADING } from './actionType'

const defaultStore = {
  menuToggle: false,
  infoList: [],
  pageLoadingVal: false
}

const containers = (state = defaultStore, action) => {
  switch (action.type) {
    case MENU_TOGGLE:
      return { ...state, menuToggle: !state.menuToggle }
    case OPENPAGELOADING: {
      return {
        pageLoadingVal: true
      }
    }
    case CLOSEPAGELOADING: {
      return {
        pageLoadingVal: false
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  home,
  customer,
  alarmManagement,
  dataManagement,
  deviceManagement,
  containers,
  details,
  waterBakance
})
