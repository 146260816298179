import {
  GET_DEPT_LIST,
  GET_STATION_LIST,
  GET_SCENARIO_LIST,
  GET_SITE_LIST,
  QUALITYMSCHINE_PAGE
} from './actionType'
const defaultStore = {
  deptList: {},
  stationList: {},
  scenarioList: {},
  siteList: {},
  qualityMachineList: {}
}

export default (state = defaultStore, action) => {
  switch (action.type) {
    case GET_DEPT_LIST:
      return Object.assign(
        {},
        {
          deptList: { ...action.data }
        }
      )
    case GET_STATION_LIST:
      return Object.assign(
        {},
        {
          stationList: { ...action.data }
        }
      )
    case GET_SCENARIO_LIST:
      return Object.assign(
        {},
        {
          scenarioList: { ...action.data }
        }
      )
    case GET_SITE_LIST:
      return Object.assign(
        {},
        {
          siteList: { ...action.data }
        }
      )
    case QUALITYMSCHINE_PAGE:
      return Object.assign(
        {},
        {
          qualityMachineList: { ...action.data }
        }
      )
    default:
      return state
  }
}
