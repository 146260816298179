import React, { Component } from 'react'
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import loadable from './utils/loadable'
import 'animate.css'
import './style/base.scss'
import './style/App.scss'
import zhCN from 'antd/es/locale/zh_CN'
import { ConfigProvider } from 'antd'
import moment from 'moment' //在原有的基础上加上下面三行代码
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

// 公共模块
const DefaultLayout = loadable(() => import(/* webpackChunkName: 'default' */ './containers'))
const Application = loadable(() => import('./views/Application'))
const ProjectOverview = loadable(() => import('@/systemPsages/Home/ProjectOverview'))
const MonitoringAlong = loadable(() => import('@/systemPsages/Home/MonitoringAlong'))
const FactoryPage = loadable(() => import('@/systemPsages/Home/FactoryPage'))
const SamplePoints = loadable(() => import('@/systemPsages/Home/SamplePoints'))
const RiverRegulation = loadable(() => import('@/systemPsages/Home/RiverRegulation'))
const QualityControl = loadable(() => import('@/systemPsages/Home/QualityControl'))
const SmartWater = loadable(() => import('./systemPsages/Home/SmartWater')) // 智慧水务
const RiverBasin = loadable(() => import('@/systemPsages/Home/RiverBasin'))
const BasicInformation = loadable(() => import('@/systemPsages/Customer/basicInformation')) // 基础信息管理
const AlarmManagement = loadable(() => import('./systemPsages/AlarmManagement/alarmManagement')) // 报警管理
const DataCenter = loadable(() => import('./systemPsages/DataManagement/data')) // 数据中心
const CustomerDetail = loadable(() => import('@/systemPsages/Customer/Customer/CustomerDetail'))
const PersonalInformation = loadable(() => import('./views/PersonalInformation'))
const EquipmentBusiness = loadable(() => import('./systemPsages/DataManagement/EquipmentBusiness'))


// 基础页面
const View404 = loadable(() => import(/* webpackChunkName: '404' */ './views/Others/404'))
const View500 = loadable(() => import(/* webpackChunkName: '500' */ './views/Others/500'))
const Login = loadable(() => import(/* webpackChunkName: 'login' */ './views/Login'))

class App extends Component {
  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Router>
          <Switch>
            <Route path='/' exact render={() => <Redirect to='/Application' />} />
            <Route path='/500' component={View500} />
            <Route path='/login' component={Login} />
            <Route path='/Application' component={Application} />
            <Route path='/ProjectOverview' component={ProjectOverview} />
            <Route path='/FactoryPage' component={FactoryPage} />
            <Route path='/SamplePoints' component={SamplePoints} />
            <Route path='/EquipmentBusiness' component={EquipmentBusiness} />
            <Route path='/RiverRegulation' component={RiverRegulation} />
            <Route path='/QualityControl' component={QualityControl} />
            <Route path='/SmartWater' component={SmartWater} />
            <Route path='/RiverBasin' component={RiverBasin} />
            <Route path='/BasicInformation' component={BasicInformation} />
            <Route path='/DataCenter' component={DataCenter} />
            <Route path='/CustomerDetail' component={CustomerDetail} />
            <Route path='/AlarmManagement' component={AlarmManagement} />
            <Route path='/PersonalInformation' component={PersonalInformation} />
            <Route path='/MonitoringAlong' component={MonitoringAlong} />
            <Route path='/404' component={View404} />
            <Route component={DefaultLayout} />
          </Switch>
        </Router>
      </ConfigProvider>
    )
  }
}

export default App
