import { GET_WATER_BALANCE_LIST } from './actionType'
const defaultStore = {
    waterBalanceList: {}
}

export default (state = defaultStore, action) => {
    switch (action.type) {
        case GET_WATER_BALANCE_LIST:
            return Object.assign(
                {},
                {
                    waterBalanceList: { ...action.data }
                }
            )
        default:
            return state
    }
}
